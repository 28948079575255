import {VueComponent} from '@nuxtjs/auth-next';
import _ from 'lodash';
import Vue from 'vue';
import ErrorCatcher from '~/components/ErrorCatcher.vue';
import {appendErrorId} from '~/lib/api/types/errors/Errors';
import {WARN_EVENT_NAME} from '~/plugins/mixin-api-error-handlers';
import { ErrorResponse } from '../api/types/errors/ErrorResponse';


export class AssertionError extends Error {
}


export function assert(value: any, message: string): void {
  if (!value) {
    throw new AssertionError(message);
  }
}

export function assertNotifyOnly(value: any, message: string, data?: any): boolean {
  if (!value) {
    if (data) {
      console.error(message, data);
    } else {
      console.error(message);
    }
    return false;
  } else {
    return true;
  }
}


export function getFirstQueryValue(value: string | (string | null)[]): string | undefined {
  if (_.isString(value)) {
    return value
  } else if (_.isArray(value)) {
    return _.find((v: string | null) => !!v)
  } else {
    return undefined;
  }
}


export function genUniqueId(prefix: string = ''): string {
  return Math.random().toString(36).replace('0.', prefix).substring(0, 8).toUpperCase();
}

// https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0#gistcomment-2694461
function hashCode(s: string): string {
  let h = 0;
  for (let i = 0; i < s.length; i++) {
    h = Math.imul(16, h) + s.charCodeAt(i) | 0;
  }
  h = h < 0 ? -h : h;

  return h.toString().substr(0, 4);
}


export async function waitTimeout(ms: number) {
  await new Promise(r => setTimeout(r, ms));
}

export function loggerErrorIdFactory(name: string) {
  const factory = (name: string, console_log: (...args: any[]) => void) => {
    return (errorId: string | undefined | null, msg: string, ...args: any[]) => console_log(name, errorId ? appendErrorId(msg, errorId) : msg, ...args);
  };

  const subFactory = (errorId: string, name: string, console_log: (...args: any[]) => void) => {
    return (msg: string, ...args: any[]) => console_log(name, errorId ? appendErrorId(msg, errorId) : msg, ...args);
  };

  return {
    log: factory(name, console.log),
    logError: factory(name, console.error),
    logWarn: factory(name, console.warn),
    loggersWithErrorId: (errorId: string) => {
      return {
        log: subFactory(errorId, name, console.log),
        logError: subFactory(errorId, name, console.error),
        logWarn: subFactory(errorId, name, console.warn),
      }
    },
  }
}


export function invokeAndRet(callback: (...args: any[]) => any) {
  return callback();
}


export function getVueRefGetter(refGetter: () => $Vue): () => Vue | undefined {
  return () => {
    const vm = refGetter();
    return vm instanceof Vue ? vm as Vue : undefined;
  };
}


export function castToVue(ref: $Vue): Vue | undefined {
  return ref instanceof Vue ? ref as Vue : undefined;
}


export function castToHtmlElement<T extends HTMLElement>(ref: $Vue | null): T | undefined {
  return ref instanceof HTMLElement ? ref as T : undefined;
}


export function emitWarnToErrorCatcher(errorCatcher: $Vue, message: string) {
  const ref = castToVue(errorCatcher);
  if (ref) {
    assert(errorCatcher instanceof ErrorCatcher, 'errorCatcher must be instanceof ErrorCatcher');
    ref.$emit(WARN_EVENT_NAME, message);
  } else {
    console.warn(`Cannot emit warn, errorCatcher is undefined, message=${message}`);
  }
}


export function assertEmitToRef($ref: $Vue, eventName: string, payload?: any) {
  const ref = castToVue($ref);
  if (ref) {
    ref.$emit(eventName, payload);
  } else {
    throw new Error(`Cannot emit "${eventName}" to ref, no $ref`);
  }
}


export function emitToRef($ref: $Vue, eventName: string, payload?: any) {
  const ref = castToVue($ref);
  if (ref) {
    ref.$emit(eventName, payload);
  }
}

// https://stackoverflow.com/a/1977898/1637178
export function isImageLoaded(img: HTMLImageElement) {
  // During the onload event, IE correctly identifies any images that
  // weren’t downloaded as not complete. Others should too. Gecko-based
  // browsers act like NS4 in that they report this incorrectly.
  if (!img.complete) {
    return false;
  }

  // However, they do have two very useful properties: naturalWidth and
  // naturalHeight. These give the true size of the image. If it failed
  // to load, either of these should be zero.
  if (img.naturalWidth === 0) {
    return false;
  }

  // No other way of checking: assume it’s ok.
  return true;
}

/**
 * Based on https://stackoverflow.com/a/7557433/1637178.
 *
 * @param el HTMLElement
 * @param _default returns default if element is not HTMLElement (checked by existence of getBoundingClientRect)
 */
export function isElementInViewport(el: HTMLElement, _default: boolean = true): boolean {
  if (!('getBoundingClientRect' in el)) {
    return _default;
  }

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}


export function breadFirstSearch<T>(tree: { [key: string]: any }, key: string): T[] {
  if (key in tree) {
    return _.flatMap(tree[key], elem => [elem].concat(breadFirstSearch(elem, key)));
  } else {
    return [];
  }
}

/**
 * Check if element is behind another or is invisible
 *
 * Source: https://stackoverflow.com/a/49833666/1637178
 */
export function isElementVisible(element: HTMLElement) {
  if (!isElementVisibleByStyles(element)) return false
  else if (isBehindOtherElement(element)) return false
  return true
}

export function isElementVisibleByStyles(element: HTMLElement) {
  const styles = window.getComputedStyle(element)
  return styles.visibility !== 'hidden' && styles.display !== 'none'
}

export function isBehindOtherElement(element: HTMLElement) {
  const boundingRect = element.getBoundingClientRect()
  // adjust coordinates to get more accurate results
  const left         = boundingRect.left + 1
  const right        = boundingRect.right - 1
  const top          = boundingRect.top + 1
  const bottom       = boundingRect.bottom - 1

  if (document.elementFromPoint(left, top) !== element) return true
  else if (document.elementFromPoint(right, top) !== element) return true
  else if (document.elementFromPoint(left, bottom) !== element) return true
  else if (document.elementFromPoint(right, bottom) !== element) return true

  return false
}

export function findParentElement(elem: HTMLElement | null, opts: { containsClassName: string }): HTMLElement | undefined {
  if (!elem) {
    return undefined;
  } else if (elem.classList.contains(opts.containsClassName)) {
    return elem;
  } else {
    return findParentElement(elem.parentElement, opts);
  }
}

export function getTextChildNodes(elem: HTMLElement): Text[] {
  let found: Text[] = [];
  elem.childNodes.forEach(c => {
    if (c instanceof Text) {
      found.push(c);
    }
  });
  return found;
}

export function shorten(text: string, maxLength: number): string {
  return text.length > maxLength ? text.slice(0, maxLength) + '...' : text;
}

export function getOrThrowErrorResponse<T>(x: T | ErrorResponse): T {
  if (x instanceof ErrorResponse) {
    throw x.toError();
  } else {
    return x;
  }
}
