import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=2b2a1352&"
import script from "./TopBar.vue?vue&type=script&lang=ts&"
export * from "./TopBar.vue?vue&type=script&lang=ts&"
import style0 from "./TopBar.vue?vue&type=style&index=0&id=2b2a1352&prod&lang=postcss&"
import style1 from "./TopBar.vue?vue&type=style&index=1&id=2b2a1352&prod&lang=postcss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuDropdown: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/top-bar/MenuDropdown.vue').default,MobileMenuContactInfo: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/top-bar/MobileMenuContactInfo.vue').default,MobileMenuSocialShares: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/top-bar/MobileMenuSocialShares.vue').default,PrettyContextMenu: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/PrettyContextMenu.vue').default,IconWithAlert: require('/home/jswiecki/projects/kupujipomagaj/src/kip-frontend/components/Icon/IconWithAlert.vue').default})
